import { usePage } from '@inertiajs/vue3';
import { scrollToSelector } from '@utils/scroll-to-selector';
import { handleZoeRedirect } from '../../themes/silver-blaze/js/utils/zoe-redirect';

export const handleClickAction = (clickAction, value = '') => {
    const page = usePage();

    console.log('clickAction', clickAction);

    if (clickAction.type === 'scroll') {
        scrollToSelector('body');
        return;
    }

    if (clickAction.type === 'link') {
        window.open(clickAction.link || page.props.zoeRedirect?.link, '_blank');
        return;
    }

    handleZoeRedirect(clickAction.zoeRedirect || page.props.zoeRedirect?.link, value);
};
